html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
	background-color: #333333;
	color: #eeeeee;
	background-image: url("prism.png");
    background-repeat: repeat; 
	font-family: 'Raleway', sans-serif;
}

ol,
ul {
	list-style: none;
}

h1, h2 {
	font-family: 'Lobster', cursive;
}

h1 {
	font-size: 2em;
}

h2 {
	font-size: 1.5em;
}

h3 {
	font-size: 1.3em;
}

h4 {
	font-size: 1.2em;
}

p {
	font-size: 1em;
}

h3, h4, p, a {
	font-family: 'Raleway', sans-serif !important;
}

blockquote,
q {
	quotes: none;
}

iframe
{
    display: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.container_a {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.smaller-container {
	padding: 30px !important;
	max-width: 630px !important;
	border-radius: 4px;
	background-color: #f3f3f399;

}
.shadow {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
	0% {
	  opacity: 0.2;
	}
	100% {
	  opacity: 1;
	}
  }
  
  .fade-in {
	animation: fadeIn 0.3s ease-in;
  }

.hover-animation:hover {
	animation: growAnimation 0.5s forwards;
  }

  @keyframes growAnimation {
	from {
	  transform: scale(1);
	}
	to {
	  transform: scale(1.05);
	}
  }